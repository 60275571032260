import { Link } from "react-router-dom";
import translogo from "../../assets/img/translogo.png";

const Footer = () => {
	return (
		<footer className="text-gray-600 body-font bg-gray-100">
			<div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col text-gray-600">
				<img
					src={translogo}
					className="not_selectable  h-12 w-12 mr-2 rounded-md"
					alt="logo"
					draggable="false"
				/>
				<Link
					to="/"
					className="text-lg text-yellow-500 hover:text-yellow-600 font-bold  border-none focus:border-0 focus:outline-none"
				>
					ReadTogether
					<span className="sr-only">ReadTogether</span>
				</Link>
				<p className="text-sm text-gray-600 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">
					<strong>© 2022 </strong>
					<a
						target="_blank"
						href="https://goldinwebdesign.com"
						rel="noreferrer"
						className="text-base text-force-gray sm:inline-flex md:block lg:inline-flex"
					>
						Goldin Web
					</a>
				</p>
				<span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
					<p
						className="text-sm text-gray-600 sm:border-gray-200" //sm:border-r-2 sm:mr-4 sm:pr-4
					>
						<Link
							to="/tos"
							className="text-force-gray text-base hover:underline"
						>
							Terms of Service & Privacy Policy
						</Link>
					</p>
					{/* <br />
					<a
						className="text-gray-500"
						href="https://www.facebook.com/GoldinWebDesign/"
						key="facebook"
					>
						<svg
							fill="currentColor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							className="w-5 h-5"
							viewBox="0 0 24 24"
						>
							<path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z" />
						</svg>
					</a>
					<a
						className="ml-3 text-gray-500"
						href="https://twitter.com/goldinguy_"
						key="twitter"
					>
						<svg
							fill="currentColor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							className="w-5 h-5"
							viewBox="0 0 24 24"
						>
							<path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z" />
						</svg>
					</a>
					<a
						href="https://www.instagram.com/goldinguy_/"
						key="insta"
						className="ml-3 text-gray-500"
					>
						<svg
							fill="none"
							stroke="currentColor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							className="w-5 h-5"
							viewBox="0 0 24 24"
						>
							<rect width={20} height={20} x={2} y={2} rx={5} ry={5} />
							<path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01" />
						</svg>
					</a>
					<a
						href="https://www.linkedin.com/in/sethgoldin/"
						key="linkedin"
						className="ml-3 text-gray-500"
					>
						<svg
							fill="currentColor"
							stroke="currentColor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={0}
							className="w-5 h-5"
							viewBox="0 0 24 24"
						>
							<path
								stroke="none"
								d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
							/>
							<circle cx={4} cy={4} r={2} stroke="none" />
						</svg>
					</a>
					<a
						href="https://github.com/GoldinGuy"
						className="ml-3 text-gray-500"
						key="github"
					>
						<svg
							height="20"
							className="text-gray-500"
							viewBox="0 0 16 16"
							fill="currentColor"
							version="1.1"
							width="20"
							aria-hidden="true"
						>
							<path
								fillRule="evenodd"
								d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"
							></path>
						</svg>
					</a> */}
				</span>
			</div>
		</footer>
	);
};

export default Footer;
