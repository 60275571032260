import { useState } from "react";
import books from "../../../assets/books.json";
import SearchBar from "./searchbar_comp";
import { LazyImage } from "react-lazy-images";
import { Book } from "../../../utils/interfaces";
import Tags from "./tags_comp";
import InfiniteScroll from "react-infinite-scroll-component";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useLocation, useHistory } from "react-router-dom";

function shuffle(array: Book[]) {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
}

const BookSelect = ({ setNewBook }: { setNewBook: Function }) => {
	const location = useLocation();
	const history = useHistory();

	var custom: string[] = [];
	var enabled: string[] = [];
	const [tags, setTags] = useState({
		custom,
		enabled,
		disabled: ["Level 1", "Level 2", "Level 3", "Level 4", "Chapter Books"]
	});

	const addFilter = (tag: string) => {
		tags.custom.push(tag);
		setTags({ ...tags });
	};

	if (location.pathname !== `/vc/browse`) {
		history.push({
			pathname: `/vc/browse`
		});
	}

	return (
		<section
			className="text-gray-600 body-font overflow-y-scroll h-full bg-white not_selectable"
			id="scrollableDiv"
		>
			<div className="container px-5 py-12 mx-auto ">
				<div className="flex flex-wrap w-full mb-10 flex-col items-center text-center">
					<h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
						ReadTogether Library
					</h1>
					<p className="lg:px-10 w-full leading-relaxed text-gray-500">
						Pick from among our wide selection of books to practice your reading
					</p>
					<SearchBar addFilter={addFilter} />

					<h5 className="flex-wrap flex">
						<Tags tags={tags} setTags={setTags} />
					</h5>
				</div>
				<div className=" -m-4 -mt-8 ">
					{tags.enabled.length === 0 && tags.custom.length === 0 ? (
						<InfiniteLibrary
							books={shuffle(books.valid)}
							setNewBook={setNewBook}
						/>
					) : (
						<CatLibrary
							tags={tags}
							books={books.valid}
							setNewBook={setNewBook}
						/>
					)}
				</div>
				<div className="py-10 text-center text-sm">
					Our catalog is entirely free, open-domain books licensed under{" "}
					<a
						href="https://creativecommons.org/licenses/by/4.0/"
						target="_blank"
						rel="noreferrer"
						className="font-bold text-yellow-500"
					>
						Creative Commons
					</a>
				</div>
			</div>
		</section>
	);
};

export default BookSelect;

const InfiniteLibrary = ({ books, setNewBook }) => {
	const [viewableBooks, setViewableBooks] = useState(books.slice(0, 16));

	const loadMoreBooks = () => {
		if (viewableBooks.length + 16 < books.length) {
			setViewableBooks(books.slice(0, viewableBooks.length + 16));
		} else {
			setViewableBooks(books);
		}
	};

	return (
		<InfiniteScroll
			dataLength={viewableBooks.length}
			next={loadMoreBooks}
			hasMore={viewableBooks.length < books.length}
			loader={
				<div className="text-center pt-10 pb-5">
					{" "}
					<ScaleLoader color={"#2f2e41"} loading={true} />
				</div>
			}
			scrollableTarget="scrollableDiv"
		>
			<div className="flex flex-wrap justify-between pt-2">
				{viewableBooks.map(book => {
					return (
						<div className="xl:w-1/4 md:w-1/3 pl-3 pr-3 pt-1" key={book.slug}>
							<button
								onClick={() => setNewBook(book)}
								className="w-full focus:border-none focus:border-0 focus:outline-none rounded-lg shadow-md mb-2 hover:opacity-90"
							>
								<div className="rounded-t-lg bg-yellow-500 w-38 lg:h-36 xl:h-40 overflow-hidden bg-cover bg-center relative h-36">
									<LazyImage
										src={`https://goldinguy.github.io/EbookAssets/img/${book.slug}.png?raw=true`}
										alt=""
										placeholder={({ imageProps, ref }) =>
											book.type === "epub" ? (
												<img
													ref={ref}
													src={imageProps.src}
													draggable="false"
													alt={""}
													className="object-cover h-full w-full"
												/>
											) : (
												<div
													style={{ background: book.bg }}
													ref={ref}
													className="object-cover h-full w-full"
												/>
											)
										}
										actual={({ imageProps }) => (
											<img
												{...imageProps}
												alt=""
												draggable="false"
												className="object-cover h-full w-full"
											/>
										)}
									/>

									<span
										className={`rounded-full px-4 w-5 bg-yellow-400 text-white font-bold p-2 leading-none flex items-center justify-center absolute top-1.5 right-1.5`}
									>
										{book.level < 5 ? book.level : "Ch"}
									</span>
								</div>
								<div
									className="p-2 flex justify-center"
									style={{ minHeight: "5.1rem" }}
								>
									<div className="m-auto">
										<h4 className="text-sm text-black font-medium title-font w-full line-clamp ">
											{book.title}
										</h4>
										<p className="leading-relaxed text-black text-xs w-full whitespace-nowrap whitespace-no-wrap overflow-ellipses overflow-hidden">
											{book.author.substring(0, 20)}
										</p>
									</div>
								</div>
							</button>
						</div>
					);
				})}
			</div>
		</InfiniteScroll>
	);
};

const CatLibrary = ({ tags, books, setNewBook }) => {
	const formatText = (text: string) => {
		return text.toLowerCase().trim().replaceAll(" ", "-");
	};

	const checkFilters = (book: Book) => {
		if (tags.enabled.length !== 0 || tags.custom.length !== 0) {
			let included = false;
			included = tags.custom.some(tag => {
				let inTags = book.filters.some(cat => {
					if (formatText(cat).includes(tag)) {
						return true;
					}
					return false;
				});
				if (
					formatText(book.title).includes(tag) ||
					formatText(book.author).includes(tag) ||
					formatText(book.source).includes(tag) ||
					inTags
				) {
					return true;
				}
				return false;
			});
			if (!included) {
				if (
					tags.enabled.includes("Chapter Books") &&
					book.type === "epub" &&
					book.level >= 5
				) {
					included = true;
				}
			}
			if (!included) {
				included = tags.enabled.some(tag => {
					if (
						book.filters.includes(tag) ||
						`Level ${book.level}`.includes(tag)
					) {
						return true;
					}
					return false;
				});
			}
			return included;
		}
		return true;
	};

	return (
		<div className="flex flex-wrap justify-between pt-2">
			{shuffle(books)
				.filter(book => {
					return checkFilters(book);
				})
				.map(book => {
					return (
						<div className="xl:w-1/4 md:w-1/3 pl-3 pr-3 pt-1" key={book.slug}>
							<button onClick={() => setNewBook(book)}>
								<div className="rounded-t-lg bg-yellow-500 w-38 lg:h-36 xl:h-40 overflow-hidden bg-cover bg-center relative ">
									<LazyImage
										src={`https://goldinguy.github.io/EbookAssets/comp_img/${book.slug}.png?raw=true`}
										alt=""
										placeholder={({ imageProps, ref }) => (
											<div
												style={{ background: book.bg }}
												ref={ref}
												className="object-cover h-full w-full"
											/>
										)}
										actual={({ imageProps }) => (
											<img
												{...imageProps}
												alt=""
												draggable="false"
												className="object-cover h-full w-full"
											/>
										)}
									/>

									<span className="rounded-full px-4 w-5 bg-yellow-400 text-white font-bold p-2 leading-none flex items-center justify-center absolute top-1.5 right-1.5">
										{book.level < 5 ? book.level : "Ch"}
									</span>
								</div>
								<div
									className="p-2 flex justify-center"
									style={{ minHeight: "5.1rem" }}
								>
									<div className="m-auto">
										<h4 className="text-sm text-black font-medium title-font w-full line-clamp ">
											{book.title}
										</h4>
										<p className="leading-relaxed text-black text-xs w-full whitespace-nowrap whitespace-no-wrap overflow-ellipses overflow-hidden">
											{book.author.substring(0, 20)}
										</p>
									</div>
								</div>
							</button>
						</div>
					);
				})}
		</div>
	);
};
