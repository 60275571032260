import Ereader from "./ereader/ereader_comp";
import BookSelect from "./book_select/library_comp";
import { useLocation } from "react-router-dom";
import { BookData } from "../../utils/interfaces";
import PDFEreader from "./pdf/pdf_comp";

const ReaderWrapperComponent = ({
	bookData,
	setBookData,
	setNewBook,
	setSyncData,
	isSync
}: {
	bookData: BookData;
	setBookData: Function;
	setNewBook: Function;
	setSyncData: Function;
	isSync: boolean;
}) => {
	const location = useLocation();

	if (location.pathname === `/vc/read/${bookData.book.slug}`) {
		if (bookData.book.type === "epub") {
			return (
				<Ereader
					bookData={bookData}
					setSyncData={setSyncData}
					setBookData={setBookData}
					isSync={isSync}
				/>
			);
		} else {
			return (
				<PDFEreader
					isSync={isSync}
					bookData={bookData}
					setSyncData={setSyncData}
					setBookData={setBookData}
				/>
			);
		}
	} else {
		return <BookSelect setNewBook={setNewBook} />;
	}
};

export default ReaderWrapperComponent;
