import { useState } from "react";
import { Link } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/img/logo.png";

const HeroSection = () => {
	const [mobileExpanded, setMobileExpanded] = useState(false);

	return (
		<div className="relative bg-white overflow-hidden h-screen">
			<div className="max-w-7xl mx-auto">
				<div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32  h-screen">
					<svg
						className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
						fill="currentColor"
						viewBox="0 0 100 100"
						preserveAspectRatio="none"
						aria-hidden="true"
					>
						<polygon points="50,0 100,0 50,100 0,100" />
					</svg>
					<div className="relative pt-6 px-4 sm:px-6 lg:px-8">
						<nav
							className="relative flex items-center justify-between sm:h-10 lg:justify-start"
							aria-label="Global"
						>
							<div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
								<div className="flex items-center justify-between w-full md:w-auto">
									<div className="flex items-center">
									<img
										src={logo}
										className="not_selectable  h-12 w-12 mr-2"
										alt="logo"
										draggable="false"
									/>

									<Link
										to="/"
										className="text-lg text-yellow-500 hover:text-yellow-600 font-bold border-none focus:border-0 focus:outline-none"
									>
										ReadTogether
										<span className="sr-only">ReadTogether</span>
										</Link>
									</div>
									<div className="-mr-2 flex items-center md:hidden">
										<button
											onClick={() => setMobileExpanded(!mobileExpanded)}
											type="button"
											className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-600"
											id="main-menu"
											aria-haspopup="true"
										>
											<span className="sr-only">Open main menu</span>
											{/* Heroicon name: menu */}
											<svg
												className="h-6 w-6"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												aria-hidden="true"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth={2}
													d="M4 6h16M4 12h16M4 18h16"
												/>
											</svg>
										</button>
									</div>
								</div>
							</div>
							<div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
								<AnchorLink
									className="font-medium text-gray-500 hover:text-gray-900"
									href="#about"
								>
									About Us
								</AnchorLink>
								<AnchorLink
									className="font-medium text-gray-500 hover:text-gray-900"
									href="#littlereaders"
								>
									Little Readers
								</AnchorLink>
								<Link
									to="/session_selection"
									className="font-medium text-gray-500 hover:text-gray-900"
								>
									Get Started
								</Link>
							</div>
						</nav>
					</div>
					{/* Mobile Menu */}
					<div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
						{mobileExpanded && (
							<div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
								<div className="px-5 pt-4 flex items-center justify-between">
									<Link
										to="/"
										className="text-lg text-yellow-500 hover:text-yellow-600 font-bold  border-none focus:border-0 focus:outline-none"
									>
										ReadTogether
										<span className="sr-only">ReadTogether</span>
									</Link>
									<div className="-mr-2">
										<button
											onClick={() => setMobileExpanded(!mobileExpanded)}
											type="button"
											className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500"
										>
											<span className="sr-only">Close main menu</span>
											{/* Heroicon name: x */}
											<svg
												className="h-6 w-6"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												aria-hidden="true"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth={2}
													d="M6 18L18 6M6 6l12 12"
												/>
											</svg>
										</button>
									</div>
								</div>
								<div
									role="menu"
									aria-orientation="vertical"
									aria-labelledby="main-menu"
								>
									<div className="px-2 pt-2 pb-3 space-y-1" role="none">
										<AnchorLink
											href="#about"
											className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50"
											role="menuitem"
										>
											About Us
										</AnchorLink>
										<AnchorLink
											href="#littlereaders"
											className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50"
											role="menuitem"
										>
											Little Readers
										</AnchorLink>
										<Link
											to="/session_selection"
											className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50"
										>
											Get Started
										</Link>
									</div>
								</div>
							</div>
						)}
					</div>
					{/* mobileExpanded */}
					<img
						className="h-56 w-full object-cover sm:h-72 sm:mb-10 lg:w-full lg:h-0 mt-4 not_selectable"
						src="https://user-images.githubusercontent.com/47064842/101844368-39369900-3b1a-11eb-8ea1-99f35936898f.jpg"
						alt="library"
						draggable="false"
					/>
					<main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-40">
						<div className="sm:text-center lg:text-left">
							<h1 className="text-4xl tracking-tight font-extrabold text-dark sm:text-5xl md:text-6xl">
								<span className="block xl:inline">
									Learn to Read
									<br />
								</span>
								<span className="block text-yellow-500 xl:inline">
									With a Buddy
								</span>
							</h1>
							<p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
								{/* The continuation of reading programs for our digital future!{" "} */}
								Join "Little Readers," a program run by the awesome organization
								Little Smiles!
								<br className="" />
								Read in sync with volunteers via video chat, for free.
							</p>
							<div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
								<div className="rounded-md shadow">
									<Link
										to="/session_selection"
										className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-yellow-400 to-yellow-500 hover:from-yellow-500 hover:to-yellow-600 md:py-4 md:text-lg md:px-10"
									>
										Get Started{" "}
										<FontAwesomeIcon
											icon={faArrowCircleRight}
											className="ml-2"
										/>
									</Link>
								</div>
								<div className="mt-3 sm:mt-0 sm:ml-3">
									<AnchorLink
										href="#about"
										className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-yellow-600 bg-yellow-100 hover:bg-yellow-200 md:py-4 md:text-lg md:px-10"
									>
										Learn More
									</AnchorLink>
								</div>
							</div>
						</div>
					</main>
				</div>
			</div>
			<div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 ">
				<img
					className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full xl:h-screen not_selectable"
					src="https://user-images.githubusercontent.com/47064842/101844368-39369900-3b1a-11eb-8ea1-99f35936898f.jpg"
					alt="library"
					draggable="false"
				/>
				{/* https://user-images.githubusercontent.com/47064842/101844369-39cf2f80-3b1a-11eb-9186-f5dafcb39991.jpeg */}
			</div>
		</div>
	);
};
export default HeroSection;
