import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import "./tailwind.output.css";
import "./styles/theme.css";
import "./assets/font/Bitter/BitterPro-Medium.ttf";
import "./assets/font/Bookerly/Bookerly-Regular.ttf";
import ReactGA from "react-ga";

ReactGA.initialize("UA-151342946-4"); // Google Analytics tracking ID
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(<App />, document.getElementById("root"));
