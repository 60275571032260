import { useState } from "react";
import { ReactReader } from "react-reader";
import styles from "./styles/style";
import "reactjs-popup/dist/index.css";
import "rc-slider/assets/index.css";
import { BookData, RenditionThemes } from "../../../utils/interfaces";
import BookCompletePopup from "../book_complete_popup";
import PreferencesPopup from "./preferences_popup";

const Ereader = ({
	bookData,
	setBookData,
	isSync,
	setSyncData
}: {
	bookData: BookData;
	setBookData: Function;
	isSync: boolean;
	setSyncData: Function;
}) => {
	const [readerTheme, setReaderTheme] = useState({
		fontSize: 130,
		font: "Georgia",
		theme: {
			name: "light",
			backgroundColor: "white",
			color: "#000"
		}
	});
	const [readerStyles, setReaderStyles] = useState({
		...styles,
		readerArea: {
			...styles.readerArea,
			backgroundColor: readerTheme.theme.backgroundColor
		}
	});
	const [rendition, setRendition] = useState(RenditionThemes);

	const getRendition = (rend: any) => {
		setRendition(rend);
		rend.themes.fontSize(`${readerTheme.fontSize}%`);
		let rendTheme = {
			h2: {
				color: readerTheme.theme.color
			},
			h3: {
				color: readerTheme.theme.color
			},
			p: {
				color: readerTheme.theme.color
			},
			body: {
				background: readerTheme.theme.backgroundColor
			}
		};

		rend.themes.default(rendTheme);
		readerTheme.fontSize = 140;
		setReaderTheme(readerTheme);
		rend.themes.fontSize(`${readerTheme.fontSize}%`);
		rend.themes.font(readerTheme.font);
		// console.log(JSON.p(rendition.themes));
	};

	const onLocationChanged = (location: string) => {
		if (bookData.loc !== location) {
			setBookData({
				book: bookData.book,
				loc: location,
				rating: bookData.rating
			});
		}
		if (isSync)
			setSyncData({
				type: "arbitrary_data",
				loc: location,
				book: bookData.book,
				meta: "loc"
			});
	};

	return (
		<div
			style={{
				position: "relative",
				height: "100%",
				fontFamily: "Bitter"
			}}
		>
			<ReactReader
				url={`https://goldinguy.github.io/EbookAssets/${bookData.book.type}/${bookData.book.slug}.${bookData.book.type}`}
				title={bookData.book.title}
				location={bookData.loc}
				locationChanged={onLocationChanged}
				showToc={true}
				getRendition={getRendition}
				swipeable={false}
				// @ts-ignore
				styles={readerStyles}
			/>

			<PreferencesPopup
				readerTheme={readerTheme}
				rendition={rendition}
				setReaderTheme={setReaderTheme}
				setReaderStyles={setReaderStyles}
				book={bookData.book}
			/>

			<div
				style={{
					position: "fixed",
					bottom: "0.5rem",
					left: "0.5em",
					zIndex: 10,
					borderRadius: "2px",
					padding: "0.5rem"
				}}
				className={
					readerTheme.theme.name === "dark" ? "text-gray-50" : "text-gray-700"
				}
			>
				<BookCompletePopup
					bookFinished={bookData.loc === bookData.book.loc?.end}
					bookData={bookData}
					setSyncData={setSyncData}
					setBookData={setBookData}
					isSync={isSync}
				/>
			</div>
		</div>
	);
};

export default Ereader;
