import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import PuffLoader from "react-spinners/PuffLoader";

const SynchronizePopup = ({
	isEnabled,
	isSync,
	setSync,
	setSyncData,
	setSyncPop
}: {
	isEnabled: boolean;
	isSync: boolean;
	setSync: Function;
	setSyncData: Function;
	setSyncPop: Function;
}) => {
	return (
		<Popup
			open={isEnabled}
			onClose={() => {
				setSyncPop(false);
			}}
			contentStyle={{ borderRadius: 12 }}
			modal
			nested
		>
			{(
				close: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
			) => (
				<div className="rounded-md ">
					<button
						className="px-2 py-2 rounded-md bg-white block absolute border-none focus:border-0 focus:outline-none cursor-pointer text-xl"
						onClick={close}
					>
						<FontAwesomeIcon
							icon={faTimes}
							size="lg"
							title="Cancel"
							className="text-gray-700"
						/>
					</button>
					<div className="rounded-md w-full text-center pt-10 px-10 text-xl font-bold not_selectable">
						{!isSync ? (
							<div>Would You Like To Read Together?</div>
						) : (
							<div>Want To Stop Reading Together?</div>
						)}
					</div>
					<div className="py-5 flex justify-center">
						<PuffLoader
							color={!isSync ? "#F59E0B" : "#5b86e5"}
							loading={true}
							size={80}
						/>
					</div>

					<div className="rounded-md w-full text-center px-10 pb-2 text-lg not_selectable">
						{!isSync ? (
							<span className="text-base">
								Once synchronized, your session will act as if you were reading
								the same book in person. <br />
								<i>
									One person turning the page or opening/closing a book affects
									both readers
									<br />
									You can change this setting at any time via the{" "}
									<strong>Sync</strong> button
								</i>
							</span>
						) : (
							<span className="text-base">
								Once de-synchronized, your session will stop acting as if you
								were reading the same book in person. <br />
								<i>
									One person turning the page or opening/closing a book will no
									longer affect both readers
									<br />
									You can change this setting at any time via the{" "}
									<strong>Sync</strong> button
								</i>
							</span>
						)}

						<br />
						<button
							className={`rounded-xl mt-5 mb-5 px-3 py-3 text-lg font-bold text-white border-none focus:border-0 focus:outline-none not_selectable ${
								!isSync ? "bg-yellow-500" : "bg-blue-500"
							}`}
							onClick={e => {
								if (isSync) {
									setSync(false);
									setSyncData({
										type: "arbitrary_data",
										meta: "end_sync"
									});
								} else {
									setSync(true);
									setSyncData({
										type: "arbitrary_data",
										meta: "start_sync"
									});
								}
								close(e);
							}}
						>
							{!isSync ? (
								<span>Synchronize!</span>
							) : (
								<span>Desynchronize!</span>
							)}
						</button>
					</div>
				</div>
			)}
		</Popup>
	);
};
export default SynchronizePopup;
