import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Tags = ({
	tags,
	setTags
}: {
	tags: {
		custom: string[];
		enabled: string[];
		disabled: string[];
	};
	setTags: Function;
}) => {
	const toggleTag = (tag: string, key: string) => {
		let idx = tags[key].indexOf(tag);
		if (key === "disabled") {
			tags.disabled.splice(idx, 1);
			tags.enabled.push(tag);
		} else if (key === "enabled") {
			tags.enabled.splice(idx, 1);
			tags.disabled.push(tag);
		} else if (key === "custom") {
			tags.custom.splice(idx, 1);
		}
		setTags({ ...tags });
	};
	return (
		<>
			{Object.entries(tags).map(([key, tagArray]) => {
				return (
					tagArray
						// .sort((a, b) => {
						// 	return a < b ? -1 : a > b ? 1 : 0;
						// })
						.map((tag: string, index: number) => (
							<button
								onClick={() => {
									toggleTag(tag, key);
								}}
								className={`text-xs border-none focus:border-0 focus:outline-none font-semibold inline-block py-1 px-2 mb-1 uppercase rounded-full text-yellow-600 bg-${
									key !== "disabled" ? "yellow" : "gray"
								}-200 last:mr-0 mr-1`}
								key={"tag-" + tag}
							>
								{"#" + tag}
								{key !== "disabled" ? (
									<FontAwesomeIcon
										icon={faTimes}
										size="1x"
										title="Cancel"
										className="text-yellow-800 ml-1"
									/>
								) : (
									<></>
								)}
							</button>
						))
				);
			})}
		</>
	);
};
export default Tags;
