import SplitPane from "react-split-pane";
import { ReaderWrapper, VideoChat } from "../components";
import "../styles/split_pane.css";
import { useState } from "react";
import { MillisecondsToTime } from "../utils/functions";
import {
	Book,
	sampleBook,
	sampleSyncData,
	SyncData
} from "../utils/interfaces";
import ReactGA from "react-ga";
import { useHistory, useLocation } from "react-router";
import { ToastContainer, toast } from "react-toastify";

const ReadTogetherWrapper = ({ sessionKey }: { sessionKey: string }) => {
	const history = useHistory();
	const location = useLocation();
	const start_time = new Date();
	const [booksRead, setBooksRead] = useState([["", 0]]);
	const [isSync, setSync] = useState(true);
	const [syncData, setSyncData] = useState(sampleSyncData);
	const [bookData, setBookData] = useState({
		book: sampleBook,
		loc: "1",
		rating: 0
	});

	const storage = global.localStorage;
	if (sessionKey !== "UNDEFINED") {
		storage && storage.setItem("VIDEO_KEY", sessionKey);
	}
	const [key] = useState(
		sessionKey === "UNDEFINED"
			? (storage && storage.getItem("VIDEO_KEY")
					? storage.getItem("VIDEO_KEY")
					: "UNDEFINED") ?? sessionKey
			: sessionKey
	);

	const handleSync = (arbData: string) => {
		// console.log("handle arb: " + arbData);
		var data: SyncData = JSON.parse(arbData);
		if (data.type === "arbitrary_data" && data !== syncData && isSync) {
			console.log("meta", data.meta);
			switch (data.meta) {
				case "start_sync":
					setSync(true);
					toast("Synchronized!");
					break;
				case "end_sync":
					setSync(false);
					toast("Desynchronized!");
					break;
				case "close_book":
					if (isSync)
						toast(
							() => (
								<>
									<strong>{data.book?.title}</strong> has been closed
								</>
							),
							{
								toastId: "closed_book"
							}
						);
					history.push({
						pathname: `/vc/browse`
					});
					break;
				case "loc":
					if (data.loc && data.loc !== bookData.loc && data.book)
						setBookData({
							book: data.book,
							loc: data.loc,
							rating: bookData.rating
						});
					break;
				default:
					// console.log(data.meta);
					if (data.book && data.book !== bookData.book) {
						if (location.pathname !== `/vc/read/${data.book.slug}`) {
							toast(
								() => (
									<div>
										<strong>{data.book?.title}</strong> has been selected
									</div>
								),
								{
									toastId: "selected_book"
								}
							);
							setNewBook(data.book);
						} else {
							setSync(true);
							toast("Synchronized!");
						}
					}
			}
		}
	};

	const setNewBook = (newBook: Book) => {
		if (location.pathname !== `/vc/read/${newBook.slug}`) {
			if (bookData.book.slug.length > 0 && bookData.rating) {
				setBooksRead([[bookData.book.title, bookData.rating], ...booksRead]);
				console.log(booksRead);
				ReactGA.event({
					category: "RatedBook",
					action: "closed book with rating: " + bookData.rating,
					value: bookData.rating,
					label: bookData.rating.toString()
				});
			}
			var newLoc =
				newBook.type === "epub"
					? newBook.loc?.start !== ""
						? newBook.loc?.start ?? ""
						: "epubcfi(/6/2[titlepage.xhtml]!/4/1:0)"
					: "1";
			setBookData({
				book: newBook,
				loc: newLoc,
				rating: 0
			});
			setSyncData({
				type: "arbitrary_data",
				book: newBook,
				loc: newLoc,
				meta: "n/a"
			});
			history.push({
				pathname: `/vc/read/${newBook.slug}`
			});
			ReactGA.event({
				category: "BookSelect",
				action: "Selected book " + newBook.title,
				value: newBook.level,
				label: newBook.slug
			});
		}
	};

	const submitSessionLog = () => {
		const diff = new Date().getTime() - start_time.getTime();
		console.log(MillisecondsToTime(diff), booksRead);
		let payload = {
			timestamp: new Date().toLocaleDateString("en-US", {
				hour: "numeric",
				minute: "numeric",
				second: "numeric",
				day: "numeric",
				month: "short",
				year: "numeric"
			}),
			session_id: key,
			session_length: MillisecondsToTime(diff),
			books_read: booksRead.filter(([title, rating]) => {
				return title !== "";
			})
		};
		console.log("logging ", payload);
		if (process.env.REACT_APP_CONTRIBUTE_API && diff >= 20000) {
			// minimum of 3 minutes in milliseconds
			fetch(`https://hook.integromat.com/fqcpmhlkok4omd7w1yuj4oeyd3opy3we`, {
				method: "POST",
				body: JSON.stringify(payload)
			}).then(res => {
				console.log(res);
			});
			// const request = new XMLHttpRequest();
			// request.open(
			// 	"PATCH",
			// 	"https://json.extendsclass.com/bin/e14f1e394206",
			// 	true
			// );
			// request.setRequestHeader("Content-type", "application/merge-patch+json");
			// // request.setRequestHeader(
			// // 	"Security-key",
			// // 	`${process.env.REACT_APP_CONTRIBUTE_API}`
			// // );
			// request.onreadystatechange = () => {};
			// request.send(JSON.stringify(payload));
		}
	};

	return (
		<>
			<SplitPane
				split="vertical"
				minSize={500}
				maxSize={900}
				allowResize={false}
				defaultSize={"50vw"}
			>
				<ReaderWrapper
					setNewBook={setNewBook}
					bookData={bookData}
					setBookData={setBookData}
					setSyncData={setSyncData}
					isSync={isSync}
				/>
				<VideoChat
					sessionKey={key}
					arbitraryData={syncData}
					handleArbitraryData={handleSync}
					submitSessionLog={submitSessionLog}
					setSync={setSync}
					isSync={isSync}
					setSyncData={setSyncData}
				/>
			</SplitPane>

			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover={false}
			/>
		</>
	);
};

export default ReadTogetherWrapper;
