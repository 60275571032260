import { useState, useEffect, useRef } from "react";

export function MillisecondsToTime(duration): string {
	var seconds = ((duration / 1000) % 60).toFixed(0);
	var minutes = ((duration / (1000 * 60)) % 60).toFixed(0);
	var hours = ((duration / (1000 * 60 * 60)) % 24).toFixed(0);
	var hourString =
		parseInt(hours) === 0 ? "00" : parseInt(hours) < 10 ? "0" + hours : hours;
	var minuteString = parseInt(minutes) < 10 ? "0" + minutes : minutes;
	var secondString = parseInt(seconds) < 10 ? "0" + seconds : seconds;
	return hourString + ":" + minuteString + ":" + secondString;
}

// Debounce hook
export function useDebounce(value, delay) {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(
		() => {
			// Set debouncedValue to value (passed in) after the specified delay
			const handler = setTimeout(() => {
				setDebouncedValue(value);
			}, delay);

			// Return a cleanup function that will be called every time ...
			// ... useEffect is re-called. useEffect will only be re-called ...
			// ... if value changes (see the inputs array below).
			// This is how we prevent debouncedValue from changing if value is ...
			// ... changed within the delay period. Timeout gets cleared and restarted.
			// To put it in context, if the user is typing within our app's ...
			// ... search box, we don't want the debouncedValue to update until ...
			// ... they've stopped typing for more than 500ms.
			return () => {
				clearTimeout(handler);
			};
		},
		// Only re-call effect if value changes
		// You could also add the "delay" var to inputs array if you ...
		// ... need to be able to change that dynamically.
		[value, delay]
	);

	return debouncedValue;
}

export function usePrevious(value) {
	// The ref object is a generic container whose current property is mutable ...
	// ... and can hold any value, similar to an instance property on a class
	const ref = useRef();

	// Store current value in ref
	useEffect(() => {
		ref.current = value;
	}, [value]); // Only re-run if value changes

	// Return previous value (happens before update in useEffect above)
	return ref.current;
}
