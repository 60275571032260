import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCog } from "@fortawesome/free-solid-svg-icons";
import { Book } from "../../../utils/interfaces";
import Slider from "rc-slider";
import styles from "./styles/style";

const PreferencesPopup = ({
	readerTheme,
	setReaderTheme,
	rendition,
	setReaderStyles,
	book
}: {
	readerTheme: {
		font: string;
		fontSize: number;
		theme: { name: string; color: string; backgroundColor: string };
	};
	setReaderTheme: Function;
	setReaderStyles: Function;
	rendition: {
		themes: {
			fontSize: Function;
			default: Function;
			font: Function;
			select: Function;
		};
	};
	book: Book;
}) => {
	const onToggleFontSize = (font: number) => {
		readerTheme.fontSize = font;
		setReaderTheme(readerTheme);
		rendition.themes.fontSize(`${readerTheme.fontSize}%`);
		// setReaderTheme({ ...readerTheme, fontSize: font });
		// rendition.themes.fontSize(`${readerTheme.fontSize}%`);
	};

	const onToggleTheme = (theme: string) => {
		switch (theme) {
			case "light":
				readerTheme.theme = {
					name: "light",
					backgroundColor: "#fff",
					color: "#000"
				};
				break;
			case "dark":
				readerTheme.theme = {
					name: "dark",
					backgroundColor: "#000",
					color: "#fff"
				};
				break;
			case "papyrus":
				readerTheme.theme = {
					name: "papyrus",
					backgroundColor: "#f6eed9",
					color: "#555555"
				};
				break;
			case "bamboo":
				readerTheme.theme = {
					name: "bamboo",
					backgroundColor: "#c8e4ce",
					color: "#111"
				};
				break;
		}
		setReaderTheme(readerTheme);

		let rendTheme = {
			h2: {
				color: readerTheme.theme.color
			},
			h3: {
				color: readerTheme.theme.color
			},
			p: {
				color: readerTheme.theme.color
			},
			body: {
				background: readerTheme.theme.backgroundColor
			}
		};

		rendition.themes.default(rendTheme);
		setReaderStyles({
			...styles,
			readerArea: {
				...styles.readerArea,
				backgroundColor: readerTheme.theme.backgroundColor
			},
			"#story_epub .text-font-largest": {
				fontSize: "200px !important"
			}
		});
	};

	return (
		<Popup
			trigger={
				<button
					style={{
						position: "fixed",
						top: "0.5rem",
						right: "50.5%",
						zIndex: 10,
						borderRadius: "2px",
						padding: "0.5rem"
					}}
					title="Preferences"
					className="border-none focus:border-0 focus:outline-none"
				>
					<FontAwesomeIcon
						icon={faCog}
						size="lg"
						title="Edit Preferences"
						className={
							readerTheme.theme.name === "dark"
								? "text-gray-50"
								: "text-gray-700"
						}
					/>
					<span className="text-xs block not_selectable">Theme</span>
				</button>
			}
			contentStyle={{ borderRadius: 12 }}
			modal
			nested
		>
			{(
				close: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
			) => (
				<div className="rounded-md">
					<button
						className="px-2 py-2 rounded-md bg-white block absolute border-none focus:border-0 focus:outline-none cursor-pointer text-xl"
						onClick={close}
					>
						<FontAwesomeIcon
							icon={faTimes}
							size="lg"
							title="Close Popup"
							className="text-gray-700"
						/>
					</button>
					<div className="rounded-md w-full text-center p-5 text-lgx not_selectable">
						Edit Your Reading Preferences
					</div>

					{/* Ereader Theme */}
					<strong className="rounded-md w-full pl-10 p-1 text-base  not_selectable">
						Theme
					</strong>
					<div className="px-8 pb-8 pt-6">
						<div>
							<div className="flex flex-wrap -m-4">
								<button
									className="xl:w-1/4 md:w-1/2 p-2 border-none focus:border-0 focus:outline-none"
									onClick={() => onToggleTheme("light")}
								>
									<div className="bg-gray-100 p-6 rounded-lg">
										<h2
											className="text-2xl text-gray-900 font-medium title-font mb-2 text-center  not_selectable"
											style={{ fontFamily: "Bitter" }}
										>
											Light
										</h2>
									</div>
								</button>

								<button
									className="xl:w-1/4 md:w-1/2 p-2 border-none focus:border-0 focus:outline-none"
									onClick={() => onToggleTheme("papyrus")}
								>
									<div
										className="p-6 rounded-lg"
										style={{ background: "#f6eed9" }}
									>
										<h2
											className="text-2xl text-gray-900 font-medium title-font mb-2 text-center  not_selectable"
											style={{ fontFamily: "Bitter" }}
										>
											Papyrus
										</h2>
									</div>
								</button>
								<button
									className="xl:w-1/4 md:w-1/2 p-2 border-none focus:border-0 focus:outline-none"
									onClick={() => onToggleTheme("bamboo")}
								>
									<div
										className="bg-gray-100 p-6 rounded-lg"
										style={{ background: "#c8e4ce" }}
									>
										<h2
											className="text-2xl text-gray-900 font-medium title-font mb-2 text-center not_selectable"
											style={{ fontFamily: "Bitter" }}
										>
											Bamboo
										</h2>
									</div>
								</button>
								<button
									className="xl:w-1/4 md:w-1/2 p-2 border-none focus:border-0 focus:outline-none"
									onClick={() => onToggleTheme("dark")}
								>
									<div
										className="p-6 rounded-lg"
										style={{ background: "#000" }}
									>
										<h2
											className="text-2xl text-white font-medium title-font mb-2 text-center not_selectable"
											style={{ fontFamily: "Bitter" }}
										>
											Dark
										</h2>
									</div>
								</button>
							</div>
						</div>
					</div>
					{/* Font Size */}
					<strong className="rounded-md w-full pl-10 p-1 text-base not_selectable">
						Font Size
					</strong>
					<div className="px-16 py-10 mb-8">
						<Slider
							dots
							min={80}
							max={180}
							marks={{
								80: <strong>80%</strong>,
								100: <strong>100%</strong>,
								120: <strong>120%</strong>,
								140: <strong>140%</strong>,
								160: <strong>160%</strong>,
								180: <strong>180%</strong>
							}}
							step={10}
							onChange={onToggleFontSize}
							defaultValue={readerTheme.fontSize}
						/>
					</div>
					{/* TODO: Font family */}
				</div>
			)}
		</Popup>
	);
};
export default PreferencesPopup;
