import React from "react";

const InfoSection = () => {
	return (
		<>
			<div id="info" className="py-28 bg-yellow-50">
				<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
					<div className="lg:text-center">
						<p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-dark sm:text-4xl">
							Why ReadTogether?
						</p>
						<p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
							Make an impact by improving literacy skills and helping children
							smile
						</p>
					</div>
					<div className="mt-20">
						<dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
							<div className="flex">
								<div className="flex-shrink-0">
									<div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-500 text-white">
										{/* Heroicon name: globe-alt */}
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-6 w-6"
											enableBackground="new 0 0 510 510"
											height="512"
											viewBox="0 0 510 510"
											width="512"
											fill="white"
										>
											<g>
												<path d="m240 406.844v-375c-26.704-20.532-59.587-31.844-93.64-31.844h-71.36v375h71.36c34.053 0 66.936 11.312 93.64 31.844zm-127.5-338.422h60v30h-60zm0 60h90v30h-90zm0 60h90v30h-90zm0 60h90v30h-90zm0 60h90v30h-90z" />
												<path d="m435 375v-375h-71.36c-34.053 0-66.936 11.312-93.64 31.844v375c26.704-20.532 59.587-31.844 93.64-31.844zm-97.5-306.578h60v30h-60zm-30 60h90v30h-90zm0 60h90v30h-90zm0 60h90v30h-90zm0 60h90v30h-90z" />
												<path d="m465 45v360h-101.36c-33.025 0-64.074 12.86-87.427 36.213l-21.213 21.214-21.213-21.214c-23.352-23.353-54.4-36.213-87.427-36.213h-101.36v-360h-45v405h116.36c37.032 0 71.848 14.421 98.033 40.606l19.394 19.394h42.426l19.393-19.394c26.945-26.944 58.704-40.606 94.394-40.606h120v-405z" />
											</g>
										</svg>
									</div>
								</div>
								<div className="ml-4">
									<dt className="text-lg leading-6 font-medium text-gray-900">
										Improve Reading Skills
									</dt>
									<dd className="mt-2 text-base text-gray-500">
										Many young readers are at risk of falling behind due to
										disruptions in supplemental programming during Covid or
										because of an ability to participate in traditional school.
										Our online interactive platform allows children to share the
										experience of reading a book, each having the ability to
										control the book and pacing of the story.
									</dd>
								</div>
							</div>
							<div className="flex">
								<div className="flex-shrink-0">
									<div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-500 text-white">
										{/* Heroicon name: lightning-bolt */}
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="white"
											className="h-6 w-6"
											enableBackground="new 0 0 510 510"
											height="512"
											viewBox="0 0 510 510"
											width="512"
										>
											<g>
												<path d="m225 420h60v90h-60z" />
												<path d="m225 120h60v120h-60z" />
												<path d="m225 0h60v90h-60z" />
												<path d="m225 270h60v120h-60z" />
												<path d="m315 120h90v45h-90z" />
												<path d="m315 420h90v-225h-90zm30-195h30v165h-30z" />
												<path d="m315 450h90v60h-90z" />
												<path d="m315 30h90v60h-90z" />
												<path d="m120 263.787 75 75v-47.574l-75-75z" />
												<path d="m120 173.787 75 75v-47.574l-75-75z" />
												<path d="m120 420h75v-38.787l-75-75z" />
												<path d="m120 450h75v60h-75z" />
												<path d="m120 83.787 75 75v-98.787h-75z" />
												<path d="m0 30h90v45h-90z" />
												<path d="m0 465h90v45h-90z" />
												<path d="m0 390h90v45h-90z" />
												<path d="m0 360h90v-180h-90zm30-150h30v120h-30z" />
												<path d="m0 105h90v45h-90z" />
												<path d="m435 405h75v-129.574l-75 75z" />
												<path d="m435 60h75v75h-75z" />
												<path d="m435 435h75v75h-75z" />
												<path d="m435 308 75-75v-68h-75z" />
											</g>
										</svg>
										{/*  */}
									</div>
								</div>
								<div className="ml-4">
									<dt className="text-lg leading-6 font-medium text-gray-900">
										Participate in Awesome Programs
									</dt>
									<dd className="mt-2 text-base text-gray-500">
										Stay connected to awesome programs like "Little Readers" and
										more even from a distance! Partner with another reader to
										build motivation and develop stronger literacy skills, while
										enjoying an entertaining and engaging session.
									</dd>
								</div>
							</div>
							<div className="flex">
								<div className="flex-shrink-0">
									<div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-500 text-white">
										{/* Heroicon name: scale */}
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-6 w-6"
											enableBackground="new 0 0 510 510"
											height="512"
											viewBox="0 0 510 510"
											width="512"
											fill="white"
										>
											<g>
												<path d="m105 390v-390c-33.084 0-60 26.916-60 60v345.041c12.544-9.438 28.129-15.041 45-15.041z" />
												<path d="m390 75h-180v180h180zm-30 105h-45v45h-30v-45h-45v-30h45v-45h30v45h45z" />
												<path d="m135 0v390h330v-390zm285 345h-240v-30h240zm0-60h-240v-240h240z" />
												<path d="m90 420c-24.853 0-45 20.147-45 45 0 24.853 20.147 45 45 45h375v-30h-30v-30h30v-30c-19.816 0-345.077 0-375 0z" />
											</g>
										</svg>
									</div>
								</div>
								<div className="ml-4">
									<dt className="text-lg leading-6 font-medium text-gray-900">
										Empower Your Community
									</dt>
									<dd className="mt-2 text-base text-gray-500">
										ReadTogether programs enable you to make a direct impact on
										the learning of another person. During this challenging time
										you can provide your peer with a fun, socially distant
										learning opportunity.
									</dd>
								</div>
							</div>

							<div className="flex">
								<div className="flex-shrink-0">
									<div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-500 text-white">
										{/* Heroicon name: annotation */}
										<svg
											className="h-6 w-6"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
											aria-hidden="true"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth={2}
												d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
											/>
										</svg>
									</div>
								</div>
								<div className="ml-4">
									<dt className="text-lg leading-6 font-medium text-gray-900">
										Earn Community Service
									</dt>
									<dd className="mt-2 text-base text-gray-500">
										Participate in programs that allow you to earn community
										service hours to fulfill your school requirements. Engage in
										a safe, interactive, online format with your reading buddy
										from the convenience of your home!
									</dd>
								</div>
							</div>
						</dl>
					</div>
				</div>
			</div>
			<div className="w-full text-center pb-20 bg-yellow-50">
				<a
					href="https://littlesmilesfl.org/volunteer/"
					rel="noreferrer"
					target="_blank"
					className="btn btn-dark btn-lg m-auto bg-yellow-500 hover:bg-yellow-400 px-4 py-2.5 rounded-lg text-white font-semibold"
				>
					Sign Up As A Little Readers Volunteer!
				</a>
			</div>
		</>
	);
};
export default InfoSection;
