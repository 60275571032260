import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFlag,
	faInfoCircle,
	faSync
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import VideoChat from "catalyst-vc-react";
import { SyncData } from "../../utils/interfaces";
import React, { useEffect, useState } from "react";
import SynchronizePopup from "./synchronize_popup";

const VideoChatPage = ({
	sessionKey,
	arbitraryData,
	handleArbitraryData,
	submitSessionLog,
	setSync,
	isSync,
	setSyncData
}: {
	sessionKey: string;
	arbitraryData: SyncData;
	handleArbitraryData: Function;
	submitSessionLog: Function;
	setSync: Function;
	isSync: boolean;
	setSyncData: Function;
}) => {
	const history = useHistory();
	const [showSyncPop, setSyncPop] = useState(false);

	window.addEventListener("unload", e => {
		console.log("unloaded session");
		submitSessionLog();
	});

	useEffect(() => {
		console.log(sessionKey);
	}, [sessionKey]);

	return (
		<>
			<VideoChat
				sessionKey={sessionKey}
				uniqueAppId="ca8a63c0-adb7-43c6-b36d-6b3bdd08385a"
				arbitraryData={JSON.stringify(arbitraryData)}
				onReceiveArbitraryData={handleArbitraryData}
				cstmWelcomeMsg={
					<>
						<span>
							Read with a partner by having them <strong>Get Started</strong> at{" "}
							<i>readtogetheronline.com</i> and entering the same session code
						</span>{" "}
						<strong>{sessionKey}</strong>
					</>
				}
				// onStartCall={() => {
				// 	setSync(true);
				// }}
				onEndCall={() => {
					console.log("exited session");
					submitSessionLog();
					history.push({
						pathname: `/`
					});
				}}
				hidden={{ screenshare: true, fullscreen: true }}
				themeColor="#fbbf24"
				defaults={{ showToastArea: false }}
				cstmServerAddress="https://api.catalyst.chat/"
				cstmOptionBtns={[
					<div className="ct-btn-container">
						<button
							className="ct-hover-btn ct-tooltip ct-not-selectable"
							onClick={() =>
								window.open("https://readtogetheronline.com/tos/", "_blank")
							}
						>
							<FontAwesomeIcon icon={faInfoCircle} />
							<span>Terms of Service</span>
						</button>
					</div>,
					<div className="ct-btn-container">
						<button
							className="ct-hover-btn ct-tooltip ct-not-selectable"
							onClick={() =>
								window.open("https://forms.gle/Ff3qqNQNZFJdRC22A", "_blank")
							}
						>
							<FontAwesomeIcon icon={faFlag} />
							<span>Report Issue</span>
						</button>
					</div>,
					<div className="ct-btn-container">
						<button
							className={`${
								isSync ? "ct-btn-on" : ""
							} ct-hover-btn ct-tooltip ct-not-selectable`}
							onClick={() => setSyncPop(!showSyncPop)}
						>
							<FontAwesomeIcon icon={faSync} />
							<span>Toggle Synchronize</span>
						</button>
					</div>
				]}
			/>
			<SynchronizePopup
				isSync={isSync}
				setSync={setSync}
				isEnabled={showSyncPop}
				setSyncData={setSyncData}
				setSyncPop={setSyncPop}
			/>
		</>
	);
};

export default VideoChatPage;
