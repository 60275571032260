import { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import {
	HomePage,
	ReadTogetherWrapper,
	SessionSelection,
	PrivacyPolicy
} from "../pages";

function App() {
	const [sessionKey, setVideoKey] = useState("UNDEFINED");

	return (
		<CookiesProvider>
			<BrowserRouter>
				<Switch>
					<Route path="//" exact component={HomePage} />
					<Route
						path="/session_selection"
						render={props => (
							<SessionSelection {...props} setKey={setVideoKey} />
						)}
					/>
					<Route
						path={["/vc", "/vc/browse", "/vc/:read/:book"]}
						render={() => <ReadTogetherWrapper sessionKey={sessionKey} />}
					/>
					<Route path={["/tos"]} exact component={PrivacyPolicy} />
				</Switch>
			</BrowserRouter>
		</CookiesProvider>
	);
}

export default App;
