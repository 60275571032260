import InfoSection from "./info";
import HeroSection from "./hero";
import Footer from "./footer";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import littleReadersImg from "../../assets/img/littlereaderslogo2.png";

const HomePage = () => {
	return (
		<>
			<HeroSection />
			{/* little readers */}
			<section className="px-4 sm:px-8 lg:px-24 py-32 bg-yellow-50" id="about">
				<div className="grid items-center grid-cols-1 mb-24 md:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
					<div>
						<h2 className="mb-4 text-2xl font-extrabold tracking-tight text-black md:leading-tight sm:text-left md:text-4xl">
							Empowering Literacy, Virtually
						</h2>
						<p className="mb-5 text-md text-gray-600 sm:text-left md:text-lg">
							ReadTogether is a free, volunteer-built platform that enables
							programs where children are paired with volunteers to improve
							their literacy skills and socialize through fun and engaging
							reading sessions.
							<span className="block mt-5 text-md text-gray-600 sm:text-left md:text-lg">
								Originally developed during Covid-19 as a way to allow for
								library literacy programs to occur virtually, ReadTogether now
								allows for more children to enjoy these experiences (including
								those who would never have been able otherwise).
							</span>
						</p>
						{/* <Link
							to="/session_selection"
							className="w-full btn btn-dark btn-lg bg-yellow-500 hover:bg-yellow-400 px-4 py-2.5 rounded-lg text-white sm:w-auto"
						>
							Learn More
						</Link> */}
					</div>
					<img
						className="w-full h-auto max-h-96 rounded object-cover shadow-none bg-gray-200" // object-right-top
						src="https://user-images.githubusercontent.com/47064842/123021523-3a3a6400-d3a2-11eb-980b-e6ac48b3b3bd.png"
						alt="example"
					/>
				</div>
				<div
					className="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24"
					id="littlereaders"
				>
					<div className="order-none md:order-2">
						<h2 className="mb-4 text-2xl font-extrabold tracking-tight text-black md:leading-tight sm:text-left md:text-4xl">
							Bringing Little Smiles To Little Readers
						</h2>
						<p className="mb-5 text-base text-gray-600 sm:text-left md:text-lg">
							<a
								className="text-yellow-500"
								href="https://www.boystown.org/locations/south-florida/Pages/default.aspx"
								rel="noreferrer"
								target="_blank"
							>
								Little Smiles
							</a>{" "}
							serves children in difficult circumstances throughout Florida.
							Partnered with hospitals and medical support facilities from Fort
							Pierce to Miami, Little Smiles now offers a new program using
							ReadTogether at all 32 of its locations.
							<br />
							<br />
							"Little Readers" allows children to pair up with Little Smiles
							volunteers and enjoy a ReadTogether session, allowing for
							socialization and helping them continue to succeed with their
							reading.
						</p>
						{/* <a
							href="https://littlesmilesfl.org/programs/"
							rel="noreferrer"
							target="_blank"
							className="w-full btn btn-dark btn-lg bg-yellow-500 hover:bg-yellow-400 px-4 py-2.5 rounded-lg text-white sm:w-auto"
						>
							Learn More
						</a> */}
					</div>
					<img
						className="w-full h-auto max-h-96 rounded object-cover bg-gray-200 " //object-left-top
						src={littleReadersImg}
						// src="https://user-images.githubusercontent.com/47064842/123021285-ce57fb80-d3a1-11eb-9a4c-487cee163170.png"
						alt="littleReadersImg"
					/>
				</div>
			</section>
			{/* logos  */}
			<section className="px-4 py-24 mx-auto max-w-7xl">
				<h1 className="mb-3 text-3xl font-bold leading-tight text-center text-gray-900 md:text-4xl">
					Reading Together For A Cause
				</h1>
				<p className="mb-8 text-lg text-center text-gray-600">
					ReadTogether was 
					<br className="md:flex hidden" /> and 
  partnered with the
					non-profit organizations{" "}
					<a
						className="text-yellow-500"
						href="https://www.boystown.org/locations/south-florida/Pages/default.aspx"
						rel="noreferrer"
						target="_blank"
					>
						Little Smiles
					</a>{" "}
					and{" "}
					<a
						className="text-yellow-500"
						href="https://www.boystown.org/locations/south-florida/Pages/default.aspx"
						rel="noreferrer"
						target="_blank"
					>
						Boys Town Florida
					</a>
					.
				</p>
				<div className="grid grid-cols-1 sm:grid-cols-2 gap-16 mb-8 text-center lg:grid-cols-3">
					<a
						className="flex items-center justify-center"
						href="https://www.boystown.org/locations/south-florida/Pages/default.aspx"
						rel="noreferrer"
						target="_blank"
					>
						<img
							src="https://user-images.githubusercontent.com/47064842/123014235-c2fdd380-d393-11eb-9c21-09340f8cae83.png"
							alt="Boys Town"
							className="block object-contain "
						/>
					</a>
					<a
						className="flex items-center justify-center"
						href="https://littlesmilesfl.org/"
						rel="noreferrer"
						target="_blank"
					>
						<img
							src="https://user-images.githubusercontent.com/47064842/123013962-36531580-d393-11eb-8fe9-0c316c4abf2b.png"
							alt="Little Smiles"
							className="block object-contain "
						/>
					</a>

				
				</div>
			</section>

			<InfoSection />

			{/* cta  */}
			<div className="bg-white">
				<div className="max-w-4xl mx-auto py-28 px-4 sm:px-6 sm:py-18 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
					<h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
						<span className="block">Are you ready to read?</span>
						<span className="block bg-gradient-to-r from-yellow-400 to-yellow-500 bg-clip-text text-transparent">
							Start your ReadTogether session now!
						</span>
					</h2>
					<div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5 items-center">
						<Link
							to="/session_selection"
							className="flex items-center justify-center px-4 py-3  text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-yellow-400 to-yellow-500 hover:from-yellow-500 hover:to-yellow-600 mb-3 sm:mb-0"
						>
							Get Started
							<FontAwesomeIcon icon={faArrowCircleRight} className="ml-2" />
						</Link>
						<Link
							to="/"
							className="flex items-center justify-center px-4 py-3  text-base font-medium rounded-md shadow-sm bg-yellow-100 text-yellow-600 hover:bg-yellow-200 mb-3 sm:mb-0"
						>
							Learn More
						</Link>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default HomePage;
