export type Book = {
	slug: string;
	title: string;
	author: string;
	source: string;
	type: string;
	level: number;
	filters: Array<string>;
	bg: string;
	loc?: BookLocation;
};

export type BookLocation = {
	start: string;
	end?: string;
};

export type BookData = {
	book: Book;
	loc: string;
	rating: number;
};

export type SyncData = {
	type: string;
	book?: Book;
	loc?: string;
	meta?: string;
};

export const sampleBook: Book = {
	slug: "",
	title: "",
	author: "",
	source: "ReadTogether",
	type: "epub",
	bg: "",
	level: 0,
	filters: [""],
	loc: {
		start: "",
		end: ""
	}
};

export const RenditionThemes = {
	themes: {
		fontSize(string: string) {},
		default(theme: any) {},
		font(font: string) {},
		select(theme: any) {}
	}
};

export const sampleSyncData: SyncData = {
	type: "not_arbitraryData",
	book: sampleBook,
	loc: "1",
	meta: "default"
};
