import React, { useEffect, useState } from "react";
import ArrowKeysReact from "arrow-keys-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClipLoader from "react-spinners/ClipLoader";
import {
	faBackward,
	faBookOpen,
	faForward
} from "@fortawesome/free-solid-svg-icons";
import { BookData } from "../../../utils/interfaces";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import BookCompletePopup from "../book_complete_popup";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFEreader = ({
	bookData,
	setBookData,
	isSync,
	setSyncData
}: {
	bookData: BookData;
	setBookData: Function;
	isSync: boolean;
	setSyncData: Function;
}) => {
	const [numPages, setNumPages] = useState(1);
	const [url, setUrl] = useState(
		`https://goldinguy.github.io/EbookAssets/${bookData.book.type}/${bookData.book.slug}.${bookData.book.type}`
	);
	useEffect(() => {
		if (numPages === 1 && parseInt(bookData.loc) === 1) {
			setUrl(
				`https://goldinguy.github.io/EbookAssets//${bookData.book.type}/${bookData.book.slug}.${bookData.book.type}`
			);
		}
		document.getElementById("pdfReader")?.focus();
	}, [numPages, bookData.book, bookData.loc]);

	useEffect(() => {
		document.getElementById("pdfReader")?.focus();
	});

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const incrementPage = () => {
		if (parseInt(bookData.loc) < numPages) {
			setBookData({
				book: bookData.book,
				loc: parseInt(bookData.loc) + 1,
				rating: bookData.rating
			});
			if (isSync)
				setSyncData({
					type: "arbitrary_data",
					book: bookData.book,
					meta: "loc",
					loc: parseInt(bookData.loc) + 1
				});
		}
	};
	const decrementPage = () => {
		if (parseInt(bookData.loc) > 0) {
			setBookData({
				book: bookData.book,
				loc: parseInt(bookData.loc) - 1,
				rating: bookData.rating
			});
			if (isSync)
				setSyncData({
					type: "arbitrary_data",
					book: bookData.book,
					meta: "loc",
					loc: parseInt(bookData.loc) - 1
				});
		}
	};

	ArrowKeysReact.config({
		left: () => {
			decrementPage();
		},
		right: () => {
			incrementPage();
		}
	});

	return (
		<div
			id="pdfReader"
			{...ArrowKeysReact.events}
			tabIndex="1"
			className="h-full w-full flex justify-center overflow-hidden border-none focus:border-0 focus:outline-none"
			style={{ backgroundColor: bookData.book.bg }} // "#494653" }} // 585464"#f3f5fd" }}
		>
			<Document
				file={url}
				loading={<ClipLoader color={"#2f2e41"} loading={true} size={"60px"} />}
				className="m-auto not_selectable"
				renderMode="canvas"
				onLoadSuccess={onDocumentLoadSuccess}
				onSourceError={err =>
					alert("Error while retrieving document source! " + err.message)
				}
			>
				<Page
					pageNumber={parseInt(bookData.loc)}
					loading={
						<ClipLoader color={"#2f2e41"} loading={true} size={"60px"} />
					}
				/>
			</Document>

			<div
				className="text-gray-100 fixed rounded-tl-lg bottom-0 p-2 bg-opacity-60 bg-gray-500"
				style={{
					right: "50%",
					zIndex: 10
				}}
			>
				<button
					title="Next Page"
					className="border-none focus:border-0 focus:outline-none pl-2 pr-2"
					onClick={decrementPage}
				>
					<FontAwesomeIcon
						icon={faBackward}
						size="sm"
						title="Previous"
						className={"text-gray-100"}
					/>
				</button>
				<span className="not_selectable ml-2 mr-2">
					{bookData.loc}/{numPages}
				</span>

				<button
					title="Next"
					className="border-none focus:border-0 focus:outline-none pl-2 pr-2"
					onClick={incrementPage}
				>
					<FontAwesomeIcon
						icon={faForward}
						size="sm"
						title="Next"
						className={"text-gray-100"}
					/>
				</button>
			</div>
			<div className="bottom-0 fixed left-0 p-2 rounded-tr-lg bg-opacity-60 bg-gray-500 text-gray-100 ">
				<BookCompletePopup
					bookFinished={parseInt(bookData.loc) === numPages && numPages !== 1}
					bookData={bookData}
					setSyncData={setSyncData}
					setBookData={setBookData}
					isSync={isSync}
				/>
			</div>

			<span className="not_selectable fixed left-0 top-0 font-bold text-lg text-gray-200 rounded-br-lg p-2 bg-opacity-60 bg-gray-500">
				<FontAwesomeIcon
					icon={faBookOpen}
					size="lg"
					title="Close bookData.book"
					className={"text-gray-100 "}
				/>{" "}
				ReadTogether
			</span>
		</div>
	);
};

export default PDFEreader;
