import React from "react";
import { Link } from "react-router-dom";
import Footer from "./home/footer";

const PrivacyPolicy = () => {
	return (
		<>
			<div id="tos" className="py-28 min-h-screen bg-yellow-50">
				<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
					<div className="max-w-7xl mx-auto lg:pr-2 md:pb-4 lg:flex lg:items-center lg:justify-between">
						<h2 className=" text-3xl font-extrabold tracking-tight text-dark sm:text-4xl">
							<Link
								to="/"
								className="text-base text-yellow-600 font-semibold tracking-wide uppercase"
							>
								Read Together Online
							</Link>
							{/* tos */}
							<p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-dark sm:text-4xl">
								Terms of Service
							</p>
						</h2>
						<div className="mt-1 md:mt-4 lg:mt-8 lex lg:flex-shrink-0 pt-8 lg:pt-5 ">
							<span className="text-gray-400 text-md">OR</span>
							<div className="ml-3 inline-flex rounded-md shadow">
								<Link
									to="/"
									className="inline-flex  border-none focus:border-0 focus:outline-none items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-yellow-600 bg-white hover:bg-yellow-50"
								>
									Return Home
								</Link>
							</div>
						</div>
					</div>
					<div className="">
						<p className="mt-4 max-w-7xl text-lg text-gray-500 lg:mx-auto">
							The following AGREEMENT, WAIVER AND RELEASE represents the terms
							of use for the virtual reading platform ReadTogether. You may
							download a PDF version of this release{" "}
							<a
								href="/tos/ReadTogetherWaiver.pdf"
								target="_blank"
								className="font-medium text-yellow-600 hover:text-yellow-500"
							>
								here
							</a>
							.
							<br />
						</p>

						<p className="mt-4 max-w-7xl text-base text-gray-500 lg:mx-auto">
							In consideration for myself and/or my child being permitted to
							participate in virtual reading sessions using the ReadTogether
							website (hereafter known as the ACTIVITY), I hereby acknowledge
							that there are risks of emotional or personal injury and property
							damage, and I knowingly waive, release, and discharge any and all
							claims for damages which I or my child may have, or which may
							hereafter accrue to me or my child, as a result of participation
							in the above ACTIVITY. This release is intended to discharge in
							advance and absolve ReadTogether, an offering by Goldin Web, and any programs it may be
							associated with (hereafter known as the PROVIDERS), including
							their volunteers, officers, officials, employees, and agents, from
							any and all liability arising out of or connected in any way with
							participation in the above ACTIVITY, even though that liability
							may arise out of active or passive negligence or carelessness on
							the part of the PROVIDERS of the ACTIVITY.
						</p>
						<br />
						<p className="mt-4 max-w-7xl text-base text-gray-500 lg:mx-auto">
							I acknowledge that the ACTIVITY takes place with the intent to
							provide a positive environment to work on literacy skills, but
							that no guarantee is made that there will be any noticeable
							improvements in my or my child’s reading. I understand that the
							ACTIVITY is not tutoring. The PROVIDERS are not expected or
							required to provide this service to a level that I deem
							acceptable.
						</p>
						<br />
						<p className="mt-4 max-w-7xl text-base text-gray-500 lg:mx-auto">
							It is further agreed that this waiver, release, and assumption of
							risk is to be binding on my heirs, administrators, executors, and
							assigns, and that I shall indemnify and hold harmless the
							PROVIDERS, including their volunteers, officers, officials,
							employees, and agents, from any loss, liability, damage, cost, or
							expense which may arise from or is connected in any way with
							participation in the above ACTIVITY.
						</p>
						<br />
						<p className="mt-4 max-w-7xl text-base text-gray-500 lg:mx-auto">
							Additionally, I understand that my or my child’s participation in
							the above ACTIVITY exposes us to the risk of emotional injury,
							personal injury, computer viruses, and/or property damage. I
							hereby acknowledge that I am voluntarily allowing participation in
							this activity and agree to assume any risks. I agree to take any
							steps I deem necessary to protect myself and my child to any and
							all risks associated with participation in the ACTIVITY.
						</p>
						<br />
						<p className="mt-4 max-w-7xl text-base text-gray-500 lg:mx-auto">
							I hereby agree and warrant that the conditions of my environment
							are safe, free from obstructions, and are suitable for
							participation in the above ACTIVITY. I further understand that I
							must provide my own copy of the software, application, platform,
							and/or browser, and all associated equipment, to access and
							participate in the above ACTIVITY, and that the PROVIDERS will not
							provide the above for my use. I also understand and agree that any
							material downloaded, viewed, or otherwise obtained through
							participation in said ACTIVITY is done at my own risk and the
							PROVIDERS are not responsible for any loss, alteration,
							corruption, or other damage to my personal property, including
							computers, networks, and other property used as part of the
							ACTIVITY.
						</p>
						<br />
						<p className="mt-4 max-w-7xl text-base text-gray-500 lg:mx-auto">
							I understand that a parent or guardian <strong>must</strong> be
							present during the ACTIVITY.
						</p>
						<br />
						<p className="mt-4 max-w-7xl text-base text-gray-500 lg:mx-auto">
							I UNDERSTAND THAT THE PROVIDERS HAVE A CODE OF CONDUCT AND I AGREE
							TO ABIDE BY, AND REQUIRE MY CHILD TO ABIDE BY, ITS POLICIES AND
							CONDITIONS. FAILURE TO DO SO MAY RESULT IN IMMEDIATE REMOVAL FROM
							THE ABOVE REFERENCED ACTIVITY. I HAVE CAREFULLY READ THIS
							AGREEMENT, WAIVER, AND RELEASE AND FULLY UNDERSTAND ITS CONTENTS.
							I AM AWARE THAT THIS IS A RELEASE OF LIABILITY BETWEEN MYSELF AND
							THE PROVIDERS AND I SIGN IT OFF OF MY FREE WILL.
						</p>
						<br />
						<br />
						<br />
						{/* privacy policy */}
						<p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-dark sm:text-4xl">
							Privacy Policy
						</p>
						<p className="mt-4 max-w-7xl text-lg text-gray-500 lg:mx-auto">
							Seth Goldin{" "}
							<a
								href="https://github.com/GoldinGuy/"
								target="_blank"
								rel="noreferrer"
								className="text-yellow-500"
							>
								(@GoldinGuy)
							</a>{" "}
							built ReadTogether under Goldin Web LLC as a SERVICE, provided at no cost and intended
							for use as-is.
							<br />
							<br />
							This page is used to inform visitors regarding policies with the
							collection, use, and disclosure of Personal Information.
							<br />
							If you choose to use ReadTogether, then you agree to the
							collection and use of information in relation to this policy. The
							Personal Information collected is used for providing and improving
							the SERVICE. Your information will not be used or shared with
							anyone except as described in this Privacy Policy.
							<br />
						</p>
						<br />
						<p className="mt-2 text-md leading-8 font-extrabold tracking-tight text-dark sm:text-3xl">
							Information Collection and Use
						</p>
						<p className="mt-4 max-w-7xl text-base text-gray-500 lg:mx-auto">
							For a better experience while using ReadTogether, you may be asked
							to provide certain information. The information requested will be
							retained on your device and is not collected in any way.
							ReadTogether strives to use commercially acceptable means of
							protecting your privacy. However, as no method of transmission
							over the internet or method of electronic storage is 100% secure
							and reliable, ReadTogether cannot guarantee the absolute security
							of your privacy. You use ReadTogether at your own risk.
							<br />
						</p>
						<br />
						<p className="mt-2 text-md leading-8 font-extrabold tracking-tight text-dark sm:text-3xl">
							Log Data & Session Logs
						</p>
						<p className="mt-4 max-w-7xl text-base text-gray-500 lg:mx-auto">
							Whenever you use ReadTogether, if an issue arises Log Data
							(through third parties) is collected. This Log Data may include
							information such as your device Internet Protocol (“IP”) address,
							device name, operating system version, app configuration, a
							timestamp, and other statistics.
							<br />
							<br />
							ReadTogether also collects Session Logs using third party service
							providers. These Session Logs enable the platform to keep track of
							session length, what books are read and their ratings, the
							timestamp, and the Session Code. <br />
							<br />
							ALL DATA is collected anonymously. No personally identifiable
							information is every collected, save Session Codes, which
							organizations running programs on the ReadTogether platform use
							for accountability and community service hours.
						</p>
						<br />
						<p className="mt-2 text-md leading-8 font-extrabold tracking-tight text-dark sm:text-3xl">
							Service Providers
						</p>
						<p className="mt-4 max-w-7xl text-base text-gray-500 lg:mx-auto">
							ReadTogether employs third-party companies and individuals due to
							the following reasons:
							<br />
							<span className="font-semibold">
								- To facilitate our Service <br />
								- To provide the Service on our behalf
								<br /> - To perform Service-related services
								<br />
								- To assist us in analyzing how our Service is used
								<br />
							</span>
							<br />
							These third parties may have anonymous access to some of your data
							if it is required perform the tasks assigned to them. However,
							they are obligated not to disclose or use the information for any
							other purpose. Privacy policies of third party service providers:
							<ul>
								<li>
									<a
										href="https://catalyst.chat/privacy_policy"
										target="_blank"
										rel="noreferrer"
										className="text-yellow-500"
									>
										- Catalyst Video Services
									</a>
								</li>
								<li>
									<a
										href="https://support.integromat.com/hc/en-us/articles/360001988174-Privacy-Policy"
										target="_blank"
										rel="noreferrer"
										className="text-yellow-500"
									>
										- Integromat Automation
									</a>
								</li>
								<li>
									<a
										href="https://policies.google.com/privacy?hl=en-US"
										target="_blank"
										rel="noreferrer"
										className="text-yellow-500"
									>
										- Google Analytics
									</a>
								</li>
								<li>
									<a
										href="https://docs.github.com/en/github/site-policy/github-privacy-statement"
										target="_blank"
										rel="noreferrer"
										className="text-yellow-500"
									>
										- GitHub Pages
									</a>
								</li>
								<li>
									<a
										href="https://sentry.io/privacy/"
										target="_blank"
										rel="noreferrer"
										className="text-yellow-500"
									>
										- Sentry
									</a>
								</li>
							</ul>
							<br />
							ReadTogether may also contain links to other sites. If you click
							on a third-party link, you will be directed to that site. These
							external sites are not operated by ReadTogether, therefore, you
							are strongly advised to review the Privacy Policy of these
							websites. ReadTogether has no control over and assumes no
							responsibility for the content, privacy policies, or practices of
							any third-party sites or services.
						</p>
						<br />
						<p className="mt-2 text-md leading-8 font-extrabold tracking-tight text-dark sm:text-3xl">
							Cookies
						</p>
						<p className="mt-4 max-w-7xl text-base text-gray-500 lg:mx-auto">
							Cookies are files with a small amount of data that are commonly
							used as anonymous unique identifiers. These are sent to your
							browser from the websites that you visit and are stored on your
							device's internal memory. ReadTogether requires cookies to
							function properly, storing the session code as a temporary cookie
							to avoid having your session broken when your page refreshes.
							<br />
							<br />
							ReadTogether may use third party code and libraries that use
							“cookies” to collect anonymous information. You have the option to
							either accept or refuse these cookies and know when a cookie is
							being sent to your device. If you choose to refuse cookies, you
							may not be able to use some portions of this Service.
						</p>
						<br />
						{/* <p className="mt-2 text-md leading-8 font-extrabold tracking-tight text-dark sm:text-3xl">
						Children's Privacy
					</p>
					<p className="mt-4 max-w-7xl text-base text-gray-500 lg:mx-auto">
						These Services do not address anyone under the age of 13.
						ReadTogether does not knowingly collect personally identifiable
						information from children under 13. In the circumstance that a child
						under 13 has provided ReadTogether with personally-identifiable
						information, it will be immediately deleted from our servers.
					</p>
					<br /> */}
						<p className="mt-2 text-md leading-8 font-extrabold tracking-tight text-dark sm:text-3xl">
							Changes to This Privacy Policy
						</p>
						<p className="mt-4 max-w-7xl text-base text-gray-500 lg:mx-auto">
							ReadTogether reserves the right to update this Privacy Policy at
							any time. You are advised to review this page periodically for any
							changes. You will be notified of any changes by viewing the new
							privacy policy on this page. These changes are effective
							immediately after they are posted on this page.
							<br />
							If you have any questions about this Privacy Policy, contact me at{" "}
							<a href="mailto:seth@goldinwebdesign.com?subject=ReadTogether%20PrivacyInquiry">
								seth@goldinwebdesign.com.
							</a>
						</p>
						<br />
						<br />
						<br />
						<p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-dark sm:text-4xl">
							Credits
						</p>
						<p className="mt-4 max-w-7xl text-lg text-gray-500 lg:mx-auto">
							ReadTogether was built in collaboration with the Palm Beach County{" "}
							<a
								href="http://www.pbclibrary.org/locations/gardens"
								target="_blank"
								rel="noreferrer"
								className="text-yellow-500"
							>
								Gardens Branch Public Library
							</a>
							.
							<br />
							<br />
							The ReadTogether catalog is entirely free, open-domain books
							licensed under{" "}
							<a
								href="https://creativecommons.org/licenses/by/4.0/"
								target="_blank"
								rel="noreferrer"
								className="font-bold text-yellow-500"
							>
								Creative Commons
							</a>
							. Book covers are either part of the stories themselves or
							copyright-free vectors.
							<br />
							<br />
							Books have been gathered from the following awesome literary
							resources:
							<ul>
								<li>
									<a
										href="https://www.gutenberg.org/"
										target="_blank"
										rel="noreferrer"
										className="text-yellow-500"
									>
										- Project Gutenburg
									</a>
								</li>
								<li>
									<a
										href="https://standardebooks.org/"
										target="_blank"
										rel="noreferrer"
										className="text-yellow-500"
									>
										- Standard Ebooks
									</a>
								</li>
								<li>
									<a
										href="https://wordscientists.org/"
										target="_blank"
										rel="noreferrer"
										className="text-yellow-500"
									>
										- Word Scientists
									</a>
								</li>
								<li>
									<a
										href="https://www.storybookscanada.ca/"
										target="_blank"
										rel="noreferrer"
										className="text-yellow-500"
									>
										- Storybooks Canada
									</a>
								</li>
								<li>
									<a
										href="http://mustardseedbooks.org/"
										target="_blank"
										rel="noreferrer"
										className="text-yellow-500"
									>
										- Mustard Seed Books
									</a>
								</li>
								<li>
									<a
										href="https://storyweaver.org.in/"
										target="_blank"
										rel="noreferrer"
										className="text-yellow-500"
									>
										- StoryWeaver
									</a>
								</li>
								<li>
									<a
										href="https://prathambooks.org/"
										target="_blank"
										rel="noreferrer"
										className="text-yellow-500"
									>
										- Pratham Books
									</a>
								</li>

								<li>
									<a
										href="https://www.roomtoread.org/"
										target="_blank"
										rel="noreferrer"
										className="text-yellow-500"
									>
										- Room To Read
									</a>
								</li>
								<li>
									<a
										href="https://reader.letsreadasia.org/?uiLang=4846240843956224"
										target="_blank"
										rel="noreferrer"
										className="text-yellow-500"
									>
										- Let's Read
									</a>
								</li>
								<li>
									<a
										href="https://bookdash.org/"
										target="_blank"
										rel="noreferrer"
										className="text-yellow-500"
									>
										- Book Dash
									</a>
								</li>
								<li>
									<a
										href="https://www.bookbotkids.com/free-books-for-kids"
										target="_blank"
										rel="noreferrer"
										className="text-yellow-500"
									>
										- BookBot
									</a>
								</li>
								<li>
									<a
										href="https://www.icharacter.org/"
										target="_blank"
										rel="noreferrer"
										className="text-yellow-500"
									>
										- iCharacter
									</a>
								</li>
								<li>
									<a
										href="https://3asafeer.com/"
										target="_blank"
										rel="noreferrer"
										className="text-yellow-500"
									>
										- 3asafeer
									</a>
								</li>

								<li>
									<a
										href="https://www.booksie.org/"
										target="_blank"
										rel="noreferrer"
										className="text-yellow-500"
									>
										- Booksie
									</a>
								</li>
							</ul>
						</p>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};
export default PrivacyPolicy;
