import EmojiRating from "react-emoji-rating";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { BookData } from "../../utils/interfaces";
import { useHistory } from "react-router-dom";

const BookCompletePopup = ({
	bookData,
	bookFinished,
	setBookData,
	isSync,
	setSyncData
}: {
	bookData: BookData;
	bookFinished?: boolean;
	setBookData: Function;
	isSync: boolean;
	setSyncData: Function;
}) => {
	const history = useHistory();

	const setRating = (num: number) => {
		setBookData({
			book: bookData.book,
			loc: bookData.loc,
			rating: num
		});
	};

	const handleRating = (rating: string) => {
		switch (rating) {
			case "Angry":
			case "Mad":
				setRating(1);
				break;
			case "Whatever":
			case "Confused":
				setRating(2);
				break;
			case "Happy":
				setRating(3);
				break;
			case "Wow":
				setRating(4);
				break;
			case "Love":
				setRating(5);
				break;
			default:
				setRating(3);
		}
	};

	return (
		<Popup
			open={bookFinished}
			contentStyle={{ borderRadius: 12 }}
			modal
			trigger={
				<button
					type="button"
					onDoubleClick={() => {
						history.push({
							pathname: `/vc/browse`
						});
					}}
					className="border-none focus:border-0 focus:outline-none" // bottom-1.5 fixed left-1.5
				>
					<FontAwesomeIcon
						icon={faTimesCircle}
						size="lg"
						title="Double-click To Close Book"
					/>
					<span className="not_selectable text-xs block">Close</span>
				</button>
			}
		>
			{(
				close: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
			) => (
				<div className="rounded-md text-center">
					<button
						className="px-1 py-1 rounded-md bg-white block absolute border-none focus:border-0 focus:outline-none cursor-pointer text-xl"
						onClick={close}
					>
						<FontAwesomeIcon
							icon={faTimes}
							size="lg"
							title="Cancel"
							className="text-gray-700"
						/>
					</button>
					<div className="rounded-md w-full text-center pt-10 px-10 text-xl font-bold">
						You Read{" "}
						<span className="text-yellow-600">
							{bookData.book.title.replace("!", "")}
						</span>
						!
					</div>
					<div
						style={{
							zoom: 1.7,
							textAlign: "center"
						}}
					>
						<EmojiRating variant="emoticons" onChange={handleRating} />
					</div>
					<i className="text-base">Drag to rate your experience!</i>
					<br />
					<button
						className="rounded-xl mt-5 mb-5 px-3 py-3 text-lg font-bold bg-yellow-500 text-white border-none focus:border-0 focus:outline-none"
						onClick={() => {
							if (isSync)
								setSyncData({
									type: "arbitrary_data",
									book: bookData.book,
									meta: "close_book"
								});
							history.push({
								pathname: `/vc/browse`
							});
						}}
					>
						Rate Book & Close
					</button>
				</div>
			)}
		</Popup>
	);
};
export default BookCompletePopup;
