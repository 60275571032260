import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import BarLoader from "react-spinners/BarLoader";
import ReactGA from "react-ga";
import session_img from "../assets/img/girl.png";

const SessionSelection = ({ setKey }: { setKey: Function }) => {
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const [tempKey, setTempKey] = useState("UNDEFINED");
	const [isError, setError] = useState(false);

	const JoinSession = () => {
		if (tempKey.length >= 6) {
			var formattedKey = tempKey.toUpperCase().replace(" ", "-");
			setKey(formattedKey);
			setLoading(true);
			ReactGA.event({
				category: "SessionJoin",
				action: "Joined a ReadTogether Session",
				value: parseFloat(new Date().toDateString()),
				label: formattedKey
			});
			setTimeout(function () {
				history.push({
					pathname: `vc/browse`
				});
				console.log(formattedKey);

				// full_screen.enter();
			}, 2000);
		} else {
			setError(true);
		}
	};

	const handleChangeSessionKeyInput = async (
		event: React.ChangeEvent<HTMLInputElement>
	): Promise<void> => {
		const newKey = event.target.value;
		setTempKey(newKey.toUpperCase().replace(" ", "-"));
	};

	const handleEnter = async e => {
		if (e.key === "Enter") {
			JoinSession();
		}
	};

	return (
		<div className="bg-yellow-50 px-5 md:px-10 lg:px-20 min-h-full md:min-h-screen">
			<div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:pt-6 md:pb-4 lg:pt-2 lg:pb-5 lg:px-8 lg:flex xl:pt-10 xl:pb-6 lg:items-center lg:justify-between">
				<h2 className="pt-8 text-3xl font-extrabold tracking-tight text-dark sm:text-4xl">
					<span className="block">Ready to Read?</span>
					<span className="block text-yellow-500">Enter Your Session Name</span>
				</h2>
				<div className="mt-1 md:mt-4 lg:mt-8 lex lg:flex-shrink-0 pt-8 lg:pt-5 ">
					<span className="text-gray-400 text-md">OR</span>
					<div className="ml-3 inline-flex rounded-md shadow">
						<Link
							to="/"
							className="inline-flex  border-none focus:border-0 focus:outline-none items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-yellow-600 bg-white hover:bg-yellow-50"
						>
							Return Home
						</Link>
					</div>
				</div>
			</div>

			<section className="text-gray-600 body-font ">
				<div className="container px-5 mx-auto flex flex-wrap justify-center ">
					<img
						src={session_img}
						className="max-h-80 xl:max-h-96 not_selectable"
						alt="kid"
						draggable="false"
					/>

					<div className="flex flex-wrap  md:w-full justify-center">
						{loading && (
							<BarLoader
								color={"#2f2e41"}
								loading={true}
								css={`
									margin: 20px;
								`}
								height={10}
								width={300}
							/>
						)}

						{!loading && (
							<>
								<div className="pb-4 pl-4 pr-4 text-center justify-center">
									<div className="rounded-lg pt-4 pr-8 pb-8 pl-8  ">
										<input
											className="w-60 md:w-96 inline-block p-2.5 mt-2 mr-3 text-gray-900 bg-gray-200  rounded-lg focus:outline-none  focus:shadow-outline focus:border-yellow-300 border-2 transition duration-150 ease-in-out"
											type="text"
											onChange={handleChangeSessionKeyInput}
											onKeyDown={handleEnter}
											value={tempKey !== "UNDEFINED" ? tempKey : ""}
											placeholder="Enter Session Name"
											minLength={12}
											maxLength={30}
											key="sessionKey"
										/>
										<button
											onClick={JoinSession}
											className=" inline-block items-center justify-center px-5 py-2.5 border border-transparent font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 text-lg  border-none focus:border-0 focus:outline-none not_selectable"
										>
											Let's Read!
										</button>
									</div>
									{isError && (
										<div
											className="flex  text-left w-full max-w-sm mx-auto bg-white shadow-md rounded-lg overflow-hidden mb-4"
											key="errorMessage"
										>
											<div
												className="flex justify-center items-center w-12 bg-red-500"
												key="error-svg"
											>
												<svg
													className="h-6 w-6 fill-current text-white"
													viewBox="0 0 40 40"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z" />
												</svg>
											</div>

											<div className="-mx-3 py-2 px-4" key="error-msg">
												<div className="mx-3">
													<span className="text-red-500 font-semibold">
														Error
													</span>
													<p className="text-gray-600 text-sm">
														Verify that you have entered a valid session key. It
														must be at least 12 characters.
													</p>
												</div>
											</div>
										</div>
									)}
									<p className="leading-relaxed text-base">
										To{" "}
										<strong>
											<i>join</i>
										</strong>{" "}
										a session, enter the same <i>Session Name</i> as your
										partner.
										<br />
										To{" "}
										<strong>
											<i>create</i>
										</strong>{" "}
										a session, enter any 6+ character <i>Session Name</i> and
									  share it with your partner.
									</p>
								</div>
							</>
						)}
					</div>
				</div>
			</section>
		</div>
	);
};
export default SessionSelection;
